import clsx from "clsx";
import * as React from "react";
import ButtonHover from "../../ui/ButtonHover/ButtonHover";
import * as styles from "./styles.module.css";

import previewImage from "../../../images/locationmap-section-preview.webp";
import previewMobileImage from "../../../images/locationmap-section-preview-mobile.webp";

export default function LocationMapSection({ isMobile, cookiesAllowed, setCookiesAllowed }) {

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const allowCookies = () => {
    setCookie('cookiebanner', 'allow', 365);
    setCookiesAllowed(true);
  }

  return (
    <>
      <div id='standort' className={styles.fakeanchor}></div>
      <div className={styles.container}>
        <div className={styles.section}>
          <div>
            <div className={styles.heading}>
              <h1 className={clsx(styles.headingtext, styles.underline)}>Wie Sie uns finden!</h1>
              <h3>In Kapfenstein direkt neben der Gemeinde</h3>
            </div>
            <div className={styles.centercontainer}>
              <div className={styles.leftside}>
                <div className={styles.mapcontainer}>
                  {
                    cookiesAllowed &&
                    <iframe
                      title='googlemaps'
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2726.868257164765!2d15.972393151507728!3d46.88564027904097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f032460e51789%3A0xe1e4cda846bc3b65!2sPizzeria%20Ristorante%20Villaggio!5e0!3m2!1sde!2sat!4v1647543242781!5m2!1sde!2sat'
                      width='100%'
                      height='100%'
                      style={{ border: "0" }}
                      allowFullScreen=''
                      loading='lazy' />
                  }
                  {
                    !cookiesAllowed &&
                    <div className={styles.previewcontainer} onClick={allowCookies}>
                      <img className={styles.previewimage} src={isMobile ? previewMobileImage : previewImage} />
                      <div className={styles.previewoverlay}>
                        <p>
                          Erlaube Cookies um die Karte zu aktivieren!<br />
                          Klicke hier um Cookies zu erlauben.
                        </p>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className={styles.rightside}>
                <div className={styles.rightcontainer}>
                  <div className={styles.textcontainer}>
                    <p>Pizzeria Kapfenstein Imbiss</p>
                    <p>Kapfenstein 161</p>
                    <p>8353 Kapfenstein</p>
                  </div>
                  <div className={styles.button}>
                    <ButtonHover
                      href='https://www.google.at/maps/dir//kapfenstein+imbiss/'
                      size='LARGE'>
                      Route
                    </ButtonHover>
                  </div>
                  {/* <a
                  className={styles.button}
                  href='https://www.google.at/maps/dir//kapfenstein+imbiss/'>
                  Route
                </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
